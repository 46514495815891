// this is our just created themes.scss file, make sure the path to the file is correct
@use 'themes' as *;

// framework component styles
@use '@nebular/theme/styles/globals' as *;

// install the framework styles
@include nb-install() {
  @include nb-theme-global();
  //@include nb-bootstrap-global();
}

.breadcrumb-item {
  list-style: none;
}

.breadcrumb-item a {
  text-decoration: none;
}

nb-tag {
  text-transform: none !important;
}

nb-card-footer > .timestamp {
  float: right;
}

button.float-right {
  float: right !important;
}

.error {
  color: red !important;
}
